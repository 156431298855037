<script>
import store from '@/store'

export default {
    data() {
        return {
            mqttMessageWatcher: null
        }
    },
    methods: {
        watchMQTTMessage() {
            this.mqttMessageWatcher = store.watch(
                (state, getters) => getters.mqttMessage,
                (val) => {
                    if (val) this.handleMQTTMessage(JSON.parse(JSON.stringify(val)))
                }
            )
        },
        handleMQTTMessage(message) {
            console.log(message)
            throw 'must implement handleMQTTMessage()'
        }
    },
    activated() {
        this.watchMQTTMessage()
    },
    deactivated() {
        // unwatch MQTT message
        if (this.mqttMessageWatcher) this.mqttMessageWatcher()
    }
}
</script>