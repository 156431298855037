<template>
    <v-card flat>
        <v-card-text>
            {{ bindCardMsg() }} <br> {{ bindMsgTail() }}
            <v-alert :value="message" type="warning">{{ message }}</v-alert>
        </v-card-text>
        <v-card-actions class="pt-0">
            <v-btn block outline color="red" @click="bindCard">{{ $t('electronicMoney.bind-card') }}</v-btn>
        </v-card-actions>
        <waiting-dialog
            :show.sync="dialog.show"
            :msg="dialog.msg"
            :err-msg="dialog.err"
            :timeout="dialog.timeout"
            @close="shouldReloadPage"
        ></waiting-dialog>
        <message-dialog
            :show.sync="msgDialog.show"
            :msg="msgDialog.msg"
            :primary-btn="msgDialog.primaryBtn"
            :secondary-btn="msgDialog.secondaryBtn"
            @primary="closeMsgDialogFunc"
        ></message-dialog>
    </v-card>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import MessageDialog from '@/components/MessageDialog'
import MessageDialogControl from '@/mixins/MessageDialogControl'
import MQTTMessageWatcher from '@/mixins/MQTTMessageWatcher'
import { getAxiosConfig } from '@/utils/AuthService'
import { HostUrl } from '@/store'
import { publish } from '@/utils/MQTTClient.js'
import i18n from '@/i18n'

export const ElectronicMoneyType = 3 // 配合 Backend 定義
export default {
    name: 'electronic-money',
    components: { WaitingDialog, MessageDialog },
    mixins: [ WaitDialogControl, MessageDialogControl, MQTTMessageWatcher ],
    props: {
        enable: {
            type: Boolean,
            required: true
        },
        amount: {
            type: Number,
            required: true
        },
        trade: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ''    
        },
        doorId: {
            type: [Number, Array],
            required: true
        },
        action: {
            type: Number,
            required: true
        },
        transType: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            cancelBind: true,
            cards: null,
            closeMsgDialogFunc: () => {},
            startTradeMQTTFailed: false,
            continueSendApiDialog: 0
        }
    },
    watch: {
        enable() {
            if (this.enable) this.getCardsInfo()
            else this.$emit('payinfo', null)
        },
        cards() {
            this.$emit('payinfo', this.cards)
        },
        trade() {
            if (this.startTradeMQTTFailed) return
            if (this.trade) this.notifyTrade()
        }
    },
    methods: {
        handleMQTTMessage(message) {
            if (message === null) {
                console.log('[ElectronicMoney] MQTT message is Null')
                return
            } else if (!this.cancelBind && message.topic.endsWith('/electronicmoney/bind/report')) this.handleBindReport(message)
            else if (this.trade && message.topic.endsWith('/electronicmoney/trade/report')) this.handleTradeReport(message)
            else {
                console.log('[ElectronicMoney] ignore MQTT message', message)
                return
            }
        },
        handleTradeReport(message) {
            this.continueSendApiDialog = 0
            if (message.payload.code === '0' && message.payload.card_id > 0) {
                this.stopTrade()
                let payinfo = {
                    type: ElectronicMoneyType,
                    card_id: message.payload.card_id,
                    detail: message.payload.data
                }
                this.$emit('payinfo', payinfo)
            } else if (message.payload.code === '-125') {
                this.closeDialog()
                this.showDialog(message.payload.msg + i18n.t('electronicMoney.error-code') + message.payload.code + ')', i18n.t('electronicMoney.unable-sense'), 20000)
            } else if (message.payload.code === '-666666') {
                this.closeDialog()
                this.stopTrade()
                this.continueSendApiDialog = 0
                this.closeMsgDialogFunc = this.reloadPage
                if (message.payload.msg === 'FULL')
                    this.showMsgDialog(this.doorId + i18n.t('electronicMoney.door-full'), i18n.t('electronicMoney.close'))
                else
                    this.showMsgDialog(this.doorId + i18n.t('electronicMoney.door-done'), i18n.t('electronicMoney.close'))
            } else if (message.payload.code === '-9000') {
                this.closeDialog()
                this.stopTrade()
                this.continueSendApiDialog = 0
                this.closeMsgDialogFunc = this.reloadPage
                if (message.payload.msg === '驗證失敗，無法使用')
                    this.showMsgDialog(i18n.t('electronicMoney.fail-auth'), i18n.t('electronicMoney.close'))
                else if (message.payload.msg === 'no auth method') { // 0 元 票卡查詢 置物
                    this.$emit('payinfo', null)
                } else
                    this.showMsgDialog(i18n.t('electronicMoney.api-connection-error'), i18n.t('electronicMoney.close'))
            } else { // 異常情況
                console.log(message.payload.msg)
                let hinderrorcode = false
                if(message.payload.msg.indexOf("未綁定") > -1) {
                    message.payload.msg = i18n.t('electronicMoney.not-bound-yet')
                    hinderrorcode = null
                } else if (message.payload.msg.indexOf("餘額不足") > -1) {
                    message.payload.msg = message.payload.msg + '<br><br>Add Money, please'
                    hinderrorcode = true
                } else {
                    message.payload.msg = i18n.t('electronicMoney.contact-service')
                }
                this.stopTrade()
                this.closeMsgDialogFunc = () => {}
                if (hinderrorcode === null) {
                    store.commit('setSnackBar', {message: message.payload.msg, color: 'error'})
                    this.bindCard()
                } else if (hinderrorcode === true)
                    this.showMsgDialog(message.payload.msg, i18n.t('electronicMoney.close'))
                else
                    this.showMsgDialog(message.payload.msg + i18n.t('electronicMoney.error-code') + message.payload.code + ')', i18n.t('electronicMoney.close'))
            }
        },
        notifyTrade() {
            if (this.amount > 1000) {
                this.showDialog(i18n.t('paymentInfo.over-1000-hint'), '', 3000)
                return
            }
            this.closeMsgDialogFunc = this.startTrade
            this.showMsgDialog(i18n.t('electronicMoney.confirm-charge'), i18n.t('electronicMoney.confirm'))
        },
        shouldReloadPage() {
            if (this.continueSendApiDialog === 1) this.notifyBindReload()
            else if (this.continueSendApiDialog === 2) this.notifyTradeReload()
            else this.stopTrade()
        },
        shouldSendMQTTByApi() {
            if (this.continueSendApiDialog === 1) this.notifyBindByApi()
            else if (this.continueSendApiDialog === 2) this.notifyTradeByApi()
            else this.stopTrade()
        },
        notifyTradeReload() {
            this.stopTrade()
            this.continueSendApiDialog = 0
            this.closeMsgDialogFunc = this.reloadPage
            this.showMsgDialog(i18n.t('electronicMoney.network-error'), i18n.t('electronicMoney.repay'))
            // this.showMsgDialog(i18n.t('electronicMoney.network-error'), i18n.t('electronicMoney.repay'), i18n.t('electronicMoney.use-other-payment'))
        },
        notifyTradeByApi() {
            this.stopTrade()
            this.continueSendApiDialog = 0
            this.closeMsgDialogFunc = this.startTradeByApi
            this.showMsgDialog(i18n.t('electronicMoney.close-confirm-charge'), i18n.t('electronicMoney.confirm'))
            // this.showMsgDialog(i18n.t('electronicMoney.close-confirm-charge'), i18n.t('electronicMoney.confirm'), i18n.t('electronicMoney.cancel'))
        },
        stopTrade() {
            this.closeDialog()
            this.$emit('update:trade', false)
        },
        reloadPage() {
            location.reload()
        },
        startTrade() {
            this.closeMsgDialog()
            // this.showDialog(i18n.t('electronicMoney.do-not-move'), i18n.t('electronicMoney.unable-sense'), 25000)
            this.showDialog(i18n.t('electronicMoney.do-not-move'), '', 60000)
            this.continueSendApiDialog = 2
            let cards = JSON.parse(JSON.stringify(this.cards))
            cards.forEach(card => {
                card.card_id = card.id
                card.ticket_number = card.uuid
                delete card['id']
                delete card['uuid']
            })
            let topic = 'box/' + store.getters.targetBoxId + '/electronicmoney/trade'
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId,
                door_id: this.doorId,
                action: this.action,
                trade_money: this.amount,
                trade_type: 'deduct',
                trans_type: this.transType,
                cards: cards
            }, 1)
        },
        startTradeByApi() {
            this.startTradeMQTTFailed = true
            this.$emit('update:trade', true)
            this.showDialog(i18n.t('electronicMoney.do-not-move'), i18n.t('electronicMoney.transaction-failed'), 20000)
            let cards = JSON.parse(JSON.stringify(this.cards))
            cards.forEach(card => {
                card.card_id = card.id
                card.ticket_number = card.uuid
                delete card['id']
                delete card['uuid']
            })
            let payload = {
                client_id: store.getters.clientId,
                trade_money: this.amount,
                trade_type: 'deduct',
                cards: cards
            }
            let subUrl = '/api/box/' + store.getters.targetBoxId + '/electronicmoney/trade'
            axios.post(HostUrl + subUrl, payload, getAxiosConfig())
                .then((response) => {
                    if (response.data.code === 0) {
                        this.startTradeMQTTFailed = false
                    } else {
                        store.commit('setSnackBar', {
                            message: response.data.message,
                            color: 'error'
                        })
                        this.startTradeMQTTFailed = false
                    }
                    this.closeDialog()
                })
                .catch(() => {
                    this.startTradeMQTTFailed = false
                    store.commit('setSnackBar', {
                        message: i18n.t('electronicMoney.server-error'),
                        color: 'error'
                    })
                    this.closeDialog()
                })
        },
        async handleBindReport(message) {
            this.continueSendApiDialog = 0
            if (message.payload.code === '-9000') {
                this.closeDialog()
                this.stopTrade()
                this.continueSendApiDialog = 0
                this.closeMsgDialogFunc = this.reloadPage
                if (message.payload.msg === '驗證失敗，無法使用')
                    this.showMsgDialog(i18n.t('electronicMoney.fail-auth'), i18n.t('electronicMoney.close'))
                else
                    this.showMsgDialog(i18n.t('electronicMoney.api-connection-error'), i18n.t('electronicMoney.close'))
            } else if (message.payload.code !== '0') {
                this.closeDialog()
                this.cancelBind = true
                this.closeMsgDialogFunc = () => {}
                this.showMsgDialog(message.payload.msg, i18n.t('electronicMoney.close'))
            } else {
                let payload = {
                    type: message.payload.data.company,
                    uuid: message.payload.data.ticket_number
                }
                /* for issue #30
                   避免使用 promise 方式呼叫此 API
                   否則裡面透過 vm.showMsgDialog 的 dialog 的 z-index 會太大
                   導致關閉此 dialog 時快速按下外層觸發 dialog 按鈕後, 顯示的 dialog 會被蓋在 overlay 下面無法操作
                */
                try { 
                    let response = await axios.post(HostUrl + '/api/payments/electronic_money', payload, getAxiosConfig())
                    if (response.data.code === 0) {
                        payload.id = response.data.id
                        this.cards.push(payload)
                        this.closeMsgDialogFunc = () => {}
                        let msg = i18n.t('electronicMoney.has-bound')
                        if (this.amount === 0) msg = i18n.t('electronicMoney.zero-money-has-bound')
                        this.showMsgDialog(msg, i18n.t('electronicMoney.confirm'))
                    } else {
                        if (response.data.message === "此卡片已完成綁定, 可直接進行支付") {
                            let msg = i18n.t('electronicMoney.has-bound')
                            if (this.amount === 0) msg = i18n.t('electronicMoney.zero-money-has-bound')
                            response.data.message = msg
                        } else if (response.data.message === "綁定卡片超過 5 張限制, 請先刪除其他卡片") {
                            response.data.message = i18n.t('electronicMoney.exceed-five-card')
                        } else if (response.data.message.indexOf("未綁定") > -1) {
                            response.data.message = i18n.t('electronicMoney.not-bound-yet')
                        }
                        this.closeMsgDialogFunc = () => {}
                        this.showMsgDialog(response.data.message, i18n.t('electronicMoney.confirm'))
                    }
                } catch (error) {
                    store.commit('setSnackBar', {
                        message: i18n.t('electronicMoney.fail-bound'),
                        color: 'error'
                    })
                } finally {
                    this.closeDialog()
                }
            }
        },
        notifyBindByApi() {
            this.stopTrade()
            this.continueSendApiDialog = 0
            this.closeMsgDialogFunc = this.startBindByApi
            this.showMsgDialog(i18n.t('electronicMoney.confirm-bind-card'), i18n.t('electronicMoney.confirm'))
            // this.showMsgDialog(i18n.t('electronicMoney.confirm-bind-card'), i18n.t('electronicMoney.confirm'), i18n.t('electronicMoney.cancel'))
        },
        notifyBindReload() {
            this.stopTrade()
            this.continueSendApiDialog = 0
            this.closeMsgDialogFunc = this.reloadPage
            this.showMsgDialog(i18n.t('electronicMoney.network-error-bind'), i18n.t('electronicMoney.rebind'))
            // this.showMsgDialog(i18n.t('electronicMoney.network-error'), i18n.t('electronicMoney.rebind'), i18n.t('electronicMoney.use-other-payment'))
        },
        bindCard() {
            this.closeMsgDialogFunc = this.startBind
            this.showMsgDialog(i18n.t('electronicMoney.close-bind-card'), i18n.t('electronicMoney.confirm'))
        },
        bindCardMsg(){
            if (this.amount === 0) return i18n.t('electronicMoney.zero-money-bind-card-msg')
            else return i18n.t('electronicMoney.bind-card-message')
        },
        bindMsgTail() {
            if (this.amount !== 0) return ' **' + i18n.t('paymentInfo.over-1000-hint') + '**'
            else return ''
        },
        startBind() {
            this.closeMsgDialog()
            // this.showDialog(i18n.t('electronicMoney.wait-sense'), i18n.t('electronicMoney.unable-sense'), 20000)
            this.showDialog(i18n.t('electronicMoney.wait-sense'), '', 20000)
            this.continueSendApiDialog = 1
            this.cancelBind = false
            let topic = 'box/' + store.getters.targetBoxId + '/electronicmoney/bind';
            if (this.amount && this.amount > 0) {
                publish(topic, {
                    user_id: store.getters.user.id,
                    client_id: store.getters.clientId,
                    amount: this.amount
                }, 1)
            }
            else {
                publish(topic, {
                    user_id: store.getters.user.id,
                    client_id: store.getters.clientId
                }, 1)
            }
        },
        async startBindByApi() {
            this.showDialog(i18n.t('electronicMoney.wait-sense'), i18n.t('electronicMoney.unable-sense'), 20000)
            try {
                let subUrl =  '/api/box/' + store.getters.targetBoxId + '/electronicmoney/bind'
                let response = await axios.post(HostUrl + subUrl, {
                    client_id: store.getters.clientId
                }, getAxiosConfig())
                if (response.data.code === 0) {
                    console.log('startBindByApi success: ', response)
                    this.closeDialog()
                } else {
                    console.log('startBindByApi fail: ', response)
                    this.cancelBind = true
                    store.commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    })
                    this.closeDialog()
                }
            } catch {
                this.cancelBind = true
                store.commit('setSnackBar', {
                    message: i18n.t('electronicMoney.server-error'),
                    color: 'error'
                })
                this.closeDialog()
            } 
        },
        getCardsInfo() {
            var vm = this
            axios.get(HostUrl + '/api/payments/electronic_money', getAxiosConfig())
                .then(function (response) {
                    if (response.data.code === 0) vm.cards = response.data.cards
                    else {
                        store.commit('setSnackBar', {
                            message: response.data.message,
                            color: 'error'
                        })
                    }
                })
                .catch(function () {
                    store.commit('setSnackBar', {
                        message: i18n.t('electronicMoney.ticket-abnormal'),
                        color: 'error'
                    })
                })
        }
    }
}
</script>