<template>
    <v-card flat>
        <v-card-text class="ma-0 pa-0">
            <v-alert :value="message" type="warning">{{ message }}</v-alert>
            <v-subheader class="subheading">
                {{ $t('directPay.credit-card-info') }}&nbsp;
                <v-btn v-if="card" small color="info" @click="updateCard">{{editCardBtn}}</v-btn><v-btn v-if="card" :disabled="!isDisableCard" small color="error" @click="disbleCardInfo">{{disableCardBtn}}</v-btn>
            </v-subheader>
            <v-card flat v-show="editCard" subheader>
                <v-card-text class="mt-0 pt-0">
                    <div>
                        <span class="subheading">{{ $t('directPay.card-number') }}</span>
                        <div class="tpfield" id="card-number"></div>
                        <span class="subheading">{{ $t('directPay.expiry-date') }}</span>
                        <div class="tpfield" id="card-expiration-date"></div>
                        <span class="subheading">{{ $t('directPay.three-digits') }}</span>
                        <div class="tpfield" id="card-ccv"></div>
                    </div>
                </v-card-text>
            </v-card>
            <v-list v-show="!editCard" subheader>
                <v-list-tile two-line>
                    <v-list-tile-content>
                        <v-list-tile-title>
                            {{ $t('directPay.card-number') }}&nbsp;{{cardNo()}}
                        </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                        <v-chip v-if="card" color="green" text-color="white" class="subheading">
                            {{card.type}}
                        </v-chip>
                    </v-list-tile-action>
                </v-list-tile>
            </v-list>
            <v-divider></v-divider>
            <v-subheader class="subheading">{{ $t('directPay.password-validation') }}</v-subheader>
            <v-card flat subheader>
                <v-card-text class="mt-0 pt-0">
                    <v-text-field outline clearable
                        :label="hasPassword ? $t('directPay.user-pass') : $t('directPay.six-digits-number')"
                        v-model="password"
                        name="password"
                        :hint="hasPassword ? $t('directPay.user-pass') : $t('directPay.six-digits-number')"
                        counter=20
                        :append-icon="showPass ? 'visibility_off' : 'visibility'"
                        :type="showPass ? 'text' : 'password'"
                        :rules="passwordRules"
                        @click:append="showPass = !showPass"
                    ></v-text-field>
                </v-card-text>
            </v-card>
        </v-card-text>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
    </v-card>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import PasswordMixin from '@/mixins/PasswordMixin'
import { HostUrl } from '@/store'
import { getAxiosConfig, decrypt } from '@/utils/AuthService'
import i18n from '@/i18n'

export const DirectPayType = 1 // 配合 Backend 定義
export default {
    name: 'direct-pay',
    props: {
        enable: {
            type: Boolean,
            required: true,
        },
        message: {
            type: String,
            default: ''    
        }
    },
    data() {
        return {
            editCard: false,
            editCardBtn: i18n.t('directPay.change'),
            disableCardBtn: i18n.t('directPay.disable'),
            isCardValid: false,
            card: null,
            passwordRules: [
                v => !!v || (this.hasPassword ? i18n.t('directPay.user-pass') : i18n.t('directPay.six-digits-number'))
            ],
        } 
    },
    components: { WaitingDialog },
    mixins: [ WaitDialogControl, PasswordMixin ],
    watch: {
        enable() {
            if (this.enable) this.init()
            else this.$emit('payinfo', null)
        },
        isCardValid() {
            if (this.isValid) this.submit()
            else this.$emit('payinfo', null)
        },
        password() {
            if (this.isValid) this.submit()
            else this.$emit('payinfo', null)
        }
    },
    computed: {
        isValid() {
            if (this.isCardValid && this.hasPassword === false) {
                if (this.password.length === 6) return true
                else return false
            }
            else return this.isCardValid && this.password
        },
        isDisableCard() {
            return this.isCardValid && this.card
        },
        hasPassword() {
            if (!store.getters.user.hasPassword) return false
            if (decrypt(store.getters.user.hasPassword) === '1') {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        init() {
            this.initPasswordField()
            this.editCard = false
            this.editCardBtn = i18n.t('directPay.change')
            this.getCardInfo()
            this.isCardValid = this.card
        },
        onUpdate(update) {
            this.isCardValid = update.canGetPrime
        },
        setUpTPFields() {
            // eslint-disable-next-line
            TPDirect.setupSDK(13942, 'app_El9r6wUxmqgPfxW1pVYkIiDri1lBqGctvEqVhEu0mwUKmjIs85rY1k1iTru0', 'production')
            let fields = {
                number: {
                    element: '#card-number',
                    placeholder: '#### #### #### ####'
                },
                expirationDate: {
                    element: '#card-expiration-date',
                    placeholder: 'MM / YY'
                },
                ccv: {
                    element: '#card-ccv',
                    placeholder: '###'
                }
            }
            // eslint-disable-next-line
            TPDirect.card.setup({
                fields: fields,
                styles: {
                    'input': {'font-size': '20px'},
                    '.valid': { 'color': 'green' },
                    '.invalid': { 'color': 'red' }
                }
            })
            // eslint-disable-next-line
            TPDirect.card.onUpdate(this.onUpdate)
        },
        getCardInfo() {
            var self = this
            axios.get(HostUrl + '/api/payment/direct', getAxiosConfig())
                .then(function (response) {
                    if (response.data.code === 0) {
                        self.card = response.data.card
                        self.isCardValid = true
                    } else if (response.data.code === 503) {
                        self.editCard = true
                    } else {
                        store.commit('setSnackBar', {
                            message: response.data.message,
                            color: 'error'
                        })
                    }
                })
                .catch(function () {
                    store.commit('setSnackBar', {
                        message: i18n.t('directPay.fail-get-credit-info'),
                        color: 'error'
                    })
                })
        },
        async disbleCardInfo() {
            var self = this
            try {
                let response = await axios.post(HostUrl + '/api/payment/direct', {}, getAxiosConfig());
                if (response.data.code === 512) {
                    self.card = null;
                } else {
                    store.commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    });
                }
            } catch(error) {
                store.commit('setSnackBar', {
                    message: i18n.t('directPay.fail-get-credit-info'),
                    color: 'error'
                });
            }
        },
        payByPrime(enpw) {
            this.showDialog(i18n.t('directPay.charge'), i18n.t('directPay.debit-failed'))
            try {
                // eslint-disable-next-line
                let tappayStatus = TPDirect.card.getTappayFieldsStatus()
                if (tappayStatus.canGetPrime) {
                    var self = this
                    // eslint-disable-next-line
                    TPDirect.card.getPrime(function(result) {
                        if (result.status !== 0) {
                            store.commit('setSnackBar', {
                                message: result.msg,
                                color: 'error'
                            })
                        } else {
                            self.$emit('payinfo', {
                                type: DirectPayType,
                                prime: result.card.prime,
                                clientip: result.clientip,
                                card_identifier: result.card_identifier,
                                password: enpw
                            })
                        }
                    })
                } else {
                    store.commit('setSnackBar', {
                        message: i18n.t('directPay.credit-invalid'),
                        color: 'error'
                    })
                }
            } finally {
                this.closeDialog()
            }
        },
        payByToken(enpw) {
            this.$emit('payinfo', {
                type: DirectPayType,
                card_id: this.card.id,
                password: enpw
            })
        },
        cardNo() {
            if (this.card){
                return '**** **** **** ' + this.card.lastfour
            } else {
                return i18n.t('directPay.not-set-credit')
            }
        },
        updateCard() {
            if (!this.editCard) {
                this.editCard = true
                this.editCardBtn = i18n.t('directPay.cancel')
                this.isCardValid = false
            } else {
                this.editCard = false
                this.editCardBtn = i18n.t('directPay.change')
                this.isCardValid = this.card && !this.editCard
            }
        },
        submit() {
            if(this.hasPassword === false && (this.password.length !== 6 || this.password.match(/[^0-9]/g) !== null)) {
                store.commit('setSnackBar', {message: i18n.t('directPay.check-info'), color: 'error'})
                return
            }
            if (this.isValid && this.password.length > 5) {
                let enpw = this.encryptedPassword(this.password)
                if (enpw !== '') {
                    if (this.editCard || !this.card) this.payByPrime(enpw)
                    else this.payByToken(enpw)
                }
            }
        }
    },
    mounted() {
        this.setUpTPFields()
    }
}
</script>

<style scoped>
.tpfield {
    height: 40px;
    width: 100%;
    border: 1px solid gray;
    margin: 5px 0;
    padding: 5px;
}
</style>
