<script>
import { encrypt } from '@/utils/AuthService'
import i18n from '@/i18n'

export default {
    data() {
        return {
            showPass: false,
            password: '',
            passwordRules: [
                v => !!v || i18n.t('passwordMixin.enter-pass')
            ]
        }
    },
    methods: {
        encryptedPassword(pw) {
            if (pw.length > 5)
                return encrypt(pw)
            else if (this.password.length > 5)
                return encrypt(this.password)
            else
                return ''
        },
        initPasswordField() {
            this.showPass = false
            this.password = ''
        }
    }
}
</script>
